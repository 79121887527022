import React, { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FormTotal from './FormTotal'
import SelectScroll from '../base/SelectScroll'
import { AutoContext } from '../../contexts/AutoContext'
import Input from '../base/Input'
import InputMask from '../base/InputMask'
import Select from '../base/Select'
import { docTypes } from '../../helpers/travel'
import Checkbox from '../base/Checkbox'

export default function FormNoStep() {
    // eslint-disable-next-line max-len
    const { setAutoSum, carIssueYear: carIssueYearOpt, getYear, debouceHandleSubmit } = useContext(AutoContext)
    const [documentTypes] = useState(docTypes.filter((item) => item.value !== '1'))
    const { t } = useTranslation('auto')
    const { setFieldValue, errors, handleSubmit } = useFormikContext()
    const itemsRef = useRef({})
    const timer = useRef()

    useEffect(() => {
        getYear('')
        window.setTimeout(() => handleSubmit(), 500)
    }, [])

    return (
        <section className="step step-1">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <div className="travel-one__form-row" style={{ marginBottom: '0px' }}>
                        <Input label={t('holderFullName')}
                            name="holderFullName"
                            refLinlk={(el) => {
                                itemsRef.current.holderFullName = el
                            }}
                            placeholder={t('holderFullNamePH')}
                            error={errors.holderFullName || errors.holderLastname || errors.holderFirstname}
                            onChange={(e) => {
                                clearTimeout(timer.current)
                                setTimeout(() => {
                                    const str = e.target.value.replace(/[ ]+/gm, ' ')
                                    const value = str.split(' ')
                                    setFieldValue('holderLastname', value[0] || '')
                                        .then(() => setFieldValue('holderFirstname', value[1] || ''))
                                        .then(() => {
                                            value.splice(0, 2)
                                            return setFieldValue('holderPatronymic', (value && value.join(' ')) || '')
                                                .then(() => debouceHandleSubmit())
                                        })
                                }, 300)
                            }}
                        />
                    </div>
                    <div className="travel-order__row">
                        <InputMask label={t('holderPhone')}
                            name="holderPhone" placeholder="(992) 00-000-0000"
                            refLinlk={(el) => {
                                itemsRef.current.holderPhone = el
                            }}
                            error={errors.holderPhone}
                        />
                        <Select
                            label={t('holderDocType')}
                            name="holderDocType"
                            error={errors.holderDocType}
                            options={documentTypes}
                            defValue="--------"
                            inputRef={(el) => {
                                itemsRef.current.holderDocType = el
                            }}
                            onChange={(value) => {
                                setFieldValue('holderDocType', value)
                                debouceHandleSubmit()
                            }}
                            className="select auto__input"
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <Input label={t('holderDocSeries')}
                            name="holderDocSeries"
                            error={errors.holderDocSeries}
                            placeholder="AA"
                            refLinlk={(el) => {
                                itemsRef.current.holderDocSeries = el
                            }}
                            onChange={() => debouceHandleSubmit()}
                            divClass="input input__required auto__input"
                        />
                        <Input label={t('holderDocNumber')}
                            name="holderDocNumber"
                            error={errors.holderDocNumber}
                            refLinlk={(el) => {
                                itemsRef.current.holderDocNumber = el
                            }}
                            onChange={() => debouceHandleSubmit()}
                            placeholder="123123123"
                            divClass="input input__required auto__input"
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <SelectScroll
                            label={t('carIssueYear')}
                            name="carIssueYear"
                            error={errors.carIssueYear}
                            options={carIssueYearOpt}
                            defValue="--------"
                            onChange={(value) => {
                                setFieldValue('carIssueYear', value)
                                setFieldValue('carIssueYearDelta', moment().year() - value)
                                // if (values.carModel) getSum(values.carModel, value)
                                setAutoSum([])
                                setFieldValue('policeSumInsured', 0)
                                debouceHandleSubmit()
                            }}
                            filter
                            className="select select__auto-order"
                        />
                        <Input label={t('gosNomer')}
                            name="gosNomer"
                            refLinlk={(el) => {
                                itemsRef.current.gosNomer = el
                            }}
                            error={errors.gosNomer}
                            errTxt={errors.gosNomer === true ? null : t(errors.gosNomer)}
                            onChange={() => debouceHandleSubmit()}
                            placeholder="1234AA01"
                            divClass="input input__required auto__input"
                        />
                    </div>
                    <Checkbox
                        name="agree"
                        className="travel-order__text checkbox rules__check"
                        error={errors.agree}
                        refLinlk={(el) => { itemsRef.current.agree = el }}
                        onChange={() => debouceHandleSubmit()}
                        label={(
                            <>
                                {t('common:agreement1')}&nbsp;
                                <a className="auto-insurance__rules-link"
                                    href="/rules"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement2')}
                                </a>
                                &nbsp;{t('common:agreement3')}&nbsp;
                                <a
                                    className="auto-insurance__rules-link"
                                    href="/politics"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement4')}
                                </a>
                            </>
                        )} />
                    <Checkbox
                        name="noTaxiAgree"
                        className="travel-order__text checkbox rules__check"
                        error={errors.noTaxiAgree}
                        onChange={() => debouceHandleSubmit()}
                        refLinlk={(el) => { itemsRef.current.noTaxiAgree = el }}
                        label={t('common:noTaxiAgree')} />
                    <Checkbox
                        name="ageAgree"
                        className="travel-order__text checkbox rules__check"
                        error={errors.ageAgree}
                        onChange={() => debouceHandleSubmit()}
                        refLinlk={(el) => { itemsRef.current.ageAgree = el }}
                        label={t('common:ageAgree')} />
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
