import moment from 'moment'

export const initialValues = (product, couponFrom) => ({
    carMark: 0,
    carModel: 0,
    carIssueYear: 0,
    carIssueYearDelta: 0,
    policeSumInsured: 0,
    calculateInstallments: false,
    accidentAnyOption: 0,
    accident3tpOption: 0,
    damage_option: 0,
    stealing_option: 0,
    tfap_option: 0,
    no_traffic_police_option: 0,
    cr_sum_assured: 0,
    acc_sum_assured: 0,
    multiDriveOption: 0,
    agree: false,
    coupon: couponFrom || '',
    beginDate: moment().add(4, 'day').format('YYYY-MM-DD'),
    duration: '12',
    bodyNumber: '',
    vin: '',
    color: '',
    gosNomer: '',
    registrSvidet: '',
    holderFullName: '',
    holderLastname: '',
    holderFirstname: '',
    holderPatronymic: '',
    holderDocSeries: '',
    holderDocNumber: '',
    holderIssueDate: '',
    holderBirthday: '',
    holderGender: '',
    holderAddress: '',
    holderPhone: '992',
    holderEmail: '',
    driver: [],
    program: product || 'ECI_P1',
    ECI_P1: 10000,
    paymentOper: '',
    currency: 'TJS',
    noTaxiAgree: false,
    ageAgree: false,
})

export const createRequestData = (values) => ({
    issueYear: values.carIssueYear,
    damageOption: values.damageOption ? 1 : 0,
    accidentAnyOption: values.accidentAnyOption ? 1 : 0,
    accident3tpOption: values.accident3tpOption ? 1 : 0,
    marka: values.mark,
    model: values.model,
    tfapOption: values.tfapOption ? 1 : 0,
    stealingOption: values.stealingOption ? 1 : 0,
    noTraficPoliceSumAssured: values.noTraficPoliceOption || 0,
    crSumAssured: values.crSumAssured || 0,
    accSumAssured: values.accSumAssured || 0,
    duraction: values.duration,
    coupon: values.coupon,
    beginDate: values.beginDate,
    holderFirstname: values.holderFirstname,
    holderLastname: values.holderLastname,
    holderPatronymic: values.holderPatronymic,
    holderPhone: values.holderPhone,
    holderEmail: values.holderEmail,
    holderBirthday: values.holderBirthday,
    holderIssueDate: values.holderIssueDate,
    holderIssueInstitution: values.holderIssueInstitution,
    holderDocNumber: values.holderDocNumber,
    holderSeries: values.holderDocSeries,
    holderAddress: values.holderAddress,
    vin: values.vin,
    registrSvidet: values.registrSvidet,
    gosNumber: values.gosNomer,
    multiDriveOption: values.multiDriveOption ? 1 : 0,
    drivers: values.multiDriveOption ? null : values.driver,
    kaskoSumAssured: values.policeSumInsured,
    kaskoPrem: values.kaskoPrem || 0,
    crPrem: values.crPrem || 0,
    accPrem: values.accPrem || 0,
    bonusOption: 1,
    noTraficPolicePrem: values.noTraficPolicePrem || 0,
    tfapPrem: values.tfapPrem || 0,
    stealingPrem: values.stealingPrem || 0,
    damagePrem: values.damagePrem || 0,
    accident3tpPrem: values.accident3tpPrem || 0,
    accidentAnyPrem: values.accidentAnyPrem || 0,
    sumPrem: values.sumPrem || 0,
    installmentplan: values.calculateInstallments ? 1 : 0,
})

// eslint-disable-next-line consistent-return
// function getProgram(values) {
//     if (values.accidentAnyOption && values.crSumAssured) return 'ICI_P3'
//
//     if (values.crSumAssured) return 'ICI_P2'
//
//     if (values.accidentAnyOption) return 'ICI_P1'
// }

export const createRequestDataV2 = (values, isEci) => {
    const data = { ...{
        issueYear: values.carIssueYear,
        holderFirstname: values.holderFirstname,
        holderLastname: values.holderLastname,
        holderPatronymic: values.holderPatronymic,
        holderPhone: values.holderPhone,
        holderDocNumber: values.holderDocNumber,
        holderSeries: values.holderDocSeries,
        gosNumber: values.gosNomer,
        program: values.program,
        drivers: [],
    },
    ...(isEci ? {
        model: values.model,
        marka: values.mark,
        beginDate: values.beginDate,
        program: values.program,
        coupon: values.coupon,
        holderEmail: values.holderEmail,
        holderBirthday: values.holderBirthday,
        holderGender: values.holderGender,
        holderIssueDate: values.holderIssueDate,
        holderIssueInstitution: values.holderIssueInstitution,
        holderAddress: values.holderAddress,
        vin: values.vin,
        registrSvidet: values.registrSvidet,
        multiDriveOption: values.multiDriveOption ? 1 : 0,
        drivers: values.multiDriveOption ? [] : values.driver,
        installmentplan: values.calculateInstallments ? 1 : 0,
        bodyNumber: values.bodyNumber || '',
        partner: values.partner || null,
        body_type: values.carBodyType || 0,
        periodicity_payment: values.duration === '12' ? 1 : 0,
        incidentSumAssured: values.incidentSumAssured || 0,
        accidentDriverOption: values.accidentAnyOption || 0,
        crSumAssured: values.crSumAssured || 0,
        compensation: values.compensation || 0,
        accidentAnyOption: values.accidentAnyOption || 0,
        accident3tpOption: values.accident3tpOption || 0,
        oldNewOption: values.oldNewOption ? 1 : 0,
        damageCarWashSumAssured: values.damageCarWashSumAssured ? 1000 : 0,
        noTraficPoliceSumAssured: values.noTraficPoliceSumAssured ? 1000 : 0,
        sumAssured: values.policeSumInsured || 0,
        greenCardUzbOption: values.greenCardUzbOption || 0,
        greenCardKgzOption: values.greenCardKgzOption || 0,
        greenCardKazOption: values.greenCardKazOption || 0,
    } : {}) }

    if (data.coupon === '') data.coupon = null

    return data
}
