import React, { useContext, useMemo } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { AutoContext } from '../../contexts/AutoContext'
import FormNoStep from './FormNoStep'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep4 from './FormStep4'
import FormStep5 from './FormStep5'

export function AutoForm() {
    const { step, setStep, errorMsg, steps } = useContext(AutoContext)
    const { t } = useTranslation('auto')

    const stepForm = useMemo(() => {
        switch (step) {
        case 'not-step':
            return (
                <FormNoStep key="FormNoStep" />
            )
        case 1:
            return (
                <FormStep1 key="formStep1" />
            )
        case 4:
            return (
                <FormStep3 key="formStep3" />
            )
        case 2:
            return (
                <FormStep5 key="formStep5" />
            )
        case 3:
            return (
                <FormStep2 key="formStep2" />
            )
        default:
            return (
                <FormStep4 key="formStep4" />
            )
        }
    }, [step])
    return (
        <>
            <form className="travel-one__form">
                <div className="tabs__nav">
                    {
                        steps.map((item) => (
                            <button className={cn('tabs__btn', {
                                tabs__btn_active: step === item,
                                tabs__btn_finished: step > item,
                            })} onClick={(event) => {
                                event.preventDefault()
                                if (step > item) {
                                    setStep(item)
                                }
                            }}>
                                {t(`headStep${item}`)}
                            </button>
                        ))
                    }
                </div>
                <div className="travel__error" style={{ color: 'darkred', paddingBottom: '10px' }}>{errorMsg}</div>
                {stepForm}
            </form>
        </>
    )
}
