import React, { Fragment, useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import promoArrow from '../../static/icons/arrow-promo.svg'
import Button from '../common/Button'
import Checkbox from '../base/Checkbox'
import { formatPrice } from '../../helpers/globals'
import { AutoContext } from '../../contexts/AutoContext'
import { createPremSum } from '../../helpers/travel'

// const options = [
//     { hint: 'hint', name: 'accidentAnyOption', prem: 'accidentAnyPrem' },
//     { hint: 'hint', name: 'accident3tpOption', prem: 'accident3tpPrem' },
//     { hint: 'hint', name: 'damageOption', prem: 'damagePrem' },
//     { hint: 'hint', name: 'stealingOption', prem: 'stealingPrem' },
//     { hint: 'hint', name: 'tfapOption', prem: 'tfapPrem' },
// ]

const docs = {
    SCI_P1: 40,
    SCI_P2: 41,
    SCI_P3: 42,
    ECI: 43,
    eci: 43,
}

const options = [
    { hint: 'crSumAssuredHint', name: 'crSumAssured', prem: 'crSumPrem', icon: 'crush', range: createPremSum(31) },
    { hint: 'accSumAssuredHint', name: 'incidentSumAssured', prem: 'incidentPrem', icon: 'hospitalization', range: createPremSum(31) },
]

const features = [
    { hint: 'accidentAnyOptionHint', name: 'accidentAnyOption', prem: 'accidentAnyPrem', icon: 'crush' },
    { hint: 'accident3tpOptionHint', name: 'accident3tpOption', prem: 'accident3tpPrem', icon: 'crush' },
    { hint: 'damageOptionHint', name: 'oldNewOption', icon: 'pdtl', prem: 'oldNewPrem' },
    { hint: 'stealingOptionHint', name: 'damageCarWashSumAssured', icon: 'bez-gai', prem: 'damageCarWashPrem' },
    { hint: 'tfapOptionHint', name: 'noTraficPoliceSumAssured', icon: 'bez-gai', prem: 'noTraficPoliceSumPrem' },
]

export default function FormTotal() {
    const { t } = useTranslation('auto')
    const { values, validateForm, setFieldValue, handleSubmit, errors } = useFormikContext()
    const { step, nextStep, cost, fullCost, errorCoupon, costMonth, modelMarkMap, showPayment, isEci, firstScreen } = useContext(AutoContext)
    const { product } = useParams()

    return (
        <div className="travel-one__total_main auto__total_main">
            {
                cost !== false
                && (
                    <div className="auto-main__block">
                        <div className="travel-one__name auto__name">
                            {t('insuranceCost')}
                        </div>
                        <div className="travel-one__value auto__value">
                            {formatPrice(cost)}
                            <span className="travel-option__currency">tjs</span>
                        </div>
                        {
                            (values.coupon && fullCost && cost !== fullCost) ? (
                                <div className="auto__monthly" style={{ textDecoration: 'line-through', fontSize: '12pt' }}>
                                    {formatPrice(fullCost)}&nbsp;
                                    <span className="auto__monthly-summ">TJS</span>
                                </div>
                            ) : null
                        }
                        {
                            values.calculateInstallments && costMonth && (
                                <div className="auto__monthly">
                                    {t('inInstallments')}
                                    <br />
                                    <span className="auto__monthly-summ">{costMonth.join(' - ').replace(/ /g, '\u00A0')}</span>
                                </div>
                            )
                        }
                    </div>
                )
            }

            {
                (values.carMark && values.carModel && values.carIssueYear)
                    ? (
                        <div className="auto-main__block">
                            <div className="travel-one__name auto__name">{t('toCar')}</div>
                            <div className="travel-one__value travel-one__value-mb">
                                <span className="auto__model-value">
                                    {modelMarkMap.marks[values.carMark]}&nbsp;{modelMarkMap.model[values.carModel]}
                                </span>
                                <span className="auto__year-value auto__year-text">
                                    {values.carIssueYear}
                                </span>
                                <span className="auto__year-text">{t('toCarYear')}</span>
                            </div>
                        </div>
                    ) : null
            }
            {
                (values.markaTxt && values.modelTxt && values.carIssueYear) ? (
                    <div className="auto-main__block">
                        <div className="travel-one__name auto__name">{t('toCar')}</div>
                        <div className="travel-one__value travel-one__value-mb">
                            <span className="auto__model-value">
                                {values.markaTxt}&nbsp;{values.modelTxt}
                            </span>
                            <span className="auto__year-value auto__year-text">
                                {values.carIssueYear}
                            </span>
                            <span className="auto__year-text">{t('toCarYear')}</span>
                        </div>
                    </div>
                ) : null
            }

            {!firstScreen && (
                <div className="auto-option__wrap">
                    <div className="travel-one__name aside-option__name">{t('program')}</div>
                    <p className="aside__option" style={{ paddingBottom: '10px' }}>{t(values.program)}</p>
                    <div className="travel-one__name aside-option__name">{t('programsMore')}</div>
                    {
                        // eslint-disable-next-line consistent-return,array-callback-return
                        options.map(({ name }) => {
                            if (values[name]) {
                                return (
                                    <p className="aside__option">{t(name)}</p>
                                )
                            }
                        })
                    }
                    <div className="travel-one__name aside-option__name" style={{ paddingTop: '10px' }}>{t('headStep3')}</div>
                    {
                        // eslint-disable-next-line consistent-return,array-callback-return
                        features.map(({ name }) => {
                            if (values[name]) {
                                return (
                                    <p className="aside__option">{t(name)}</p>
                                )
                            }
                        })
                    }
                </div>
            )}

            {
                values.multiDrivePrem && (
                    <div className="auto-option__wrap">
                        <div className="travel-one__name aside-option__name">{t('multidrive')}</div>
                        <p className="aside__option">{values.multiDrivePrem} TJS</p>
                    </div>
                )
            }

            <div className="travel-order__promo promo">
                <p className="promo__subtitle">{t('promoCode')}</p>
                <div className="travel-order__promo-row">
                    <div className="input input__promo">
                        <label className="input__label">
                            <span className="input_error-text">{t('errorCoupon')}</span>
                        </label>
                        <input className="input__input promo__input" type="text" value={values.coupon} name="coupon" placeholder=""
                            onChange={(e) => setFieldValue('coupon', e.target.value)} />
                    </div>
                    <a className="travel-order__btn promo__btn" onClick={() => handleSubmit()}>
                        <img src={promoArrow} alt="" width="16" height="16" />
                    </a>
                </div>
                <div className="travel__error" style={{ color: 'darkred', paddingBottom: '10px' }}>{errorCoupon}</div>
            </div>
            <Button
                onClick={() => {
                    validateForm().then((e) => {
                        setFieldValue('shortValidate', false)
                        if (!e.haveErrors) {
                            if (step < 5 || step !== 'not-step') {
                                nextStep()
                            } else if (values.agree) showPayment()
                        }
                    })
                }}
                disabled={(((!values.agree || !values.noTaxiAgree || !values.ageAgree) && step === 'not-step')
                    || (!values.agree && step === 3 && !isEci)
                    || (!values.agree && step === 4 && isEci)
                    || errors.haveErrors || errorCoupon)}
                text={(step > 3 || step === 'not-step' ? t('holderAgree') : t('nextStep'))}
                className="travel-one__form-btn step-button" />

            <button
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open(`http://scm.tias.bima.tj/api/documents/${docs[product]}/ru`, '_blank')
                }}
                className="travel-option__description-button auto__description-button">
                {t('openInfoPopup')}
            </button>

            { step > 6 && (
                <Checkbox
                    name="agree"
                    className="travel-order__checkbox"
                    error={errors.agree}
                    label={(
                        <>
                            {t('common:agreement1')}&nbsp;
                            <a className="auto-insurance__rules-link"
                                href="/rules"
                                target="_blank"
                                rel="noreferrer">
                                {t('common:agreement2')}
                            </a>
                            {t('common:agreement3')}&nbsp;
                            <a
                                className="auto-insurance__rules-link"
                                href="/politics"
                                target="_blank"
                                rel="noreferrer">
                                {t('common:agreement4')}
                            </a>
                        </>
                    )} />
            )}
        </div>
    )
}
