import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { withFormikDevtools } from 'formik-devtools-extension'
import * as yup from 'yup'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { createRequestDataV2, initialValues } from '../helpers/auto'
import { useGetRequest, usePostRequest } from '../hooks/request'
import { REST } from '../utils/urls'
import { AuthContext } from './AuthContext'
import { Context } from './GlobalContext'
import { useModal } from '../hooks/modal'
import { PaymentSelector } from '../components/modals/PaymentSelector'

const validateRules = {
    beginDate: yup.date().min(moment().startOf('day').add(1, 'day').toDate()),
    duration: yup.string().required(),
    markaTxt: yup.string().required(),
    modelTxt: yup.string().required(),
    vin: yup.string().required().matches(/^([a-zA-Z0-9]*)$/).length(17),
    bodyNumber: yup.string().required().matches(/^([a-zA-Z0-9]*)$/).min(9).max(12),
    carBodyType: yup.number().required(),
    policeSumInsured: yup.number().required().min(1),
    color: yup.string().optional(),
    gosNomer: yup.string().required().test('notTaxi', 'taxi', (value) => !/(TT|tt|ТТ|тт)/.test(value)).matches(/^[0-9]{4}[a-zA-Z]{2}[0-9]{2}$/),
    registrSvidet: yup.string().required().matches(/^[a-zA-Z]{2}[ ]?[0-9]{7}$/),
    holderFullName: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    holderLastname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    holderFirstname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    holderPatronymic: yup.string().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/),
    holderGender: yup.string().required(),
    holderBirthday: yup.date().required().min(moment().add(-100, 'years').toDate()).max(moment().toDate()),
    holderDocType: yup.string().required(),
    holderDocSeries: yup.string().required().matches(/^[a-zA-Z]{1,50}$/),
    holderDocNumber: yup.string().required().matches(/^[0-9]{1,15}$/),
    holderIssueDate: yup.string().required().min(moment().add(-70, 'years').toDate()).max(moment().toDate()),
    holderAddress: yup.string().required().matches(/^([0-9а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z-., ]*)$/).min(1),
    holderPhone: yup.string().required().matches(/^[+(]*992[)]+ \d\d-\d\d\d-\d\d\d\d$/),
    holderEmail: yup.string().required().email().matches(/^([^а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷ]*)$/),
    driverFullName: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    driverLastname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    driverFirstname: yup.string().required().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/).min(1),
    driverPatronymic: yup.string().optional().matches(/^([а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z- ]*)$/),
    docSeries: yup.string().required().matches(/^[a-zA-Z]{2}$/),
    docNumber: yup.string().required().matches(/^[0-9]{7}$/),
    driverCitizenship: yup.string().required(),
    driverBd: yup.date().required(),
    driverGender: yup.string().required(),
    docIssueDate: yup.string().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-1, 'year').toDate()),
    birthday: yup.date().required().min(moment().add(-100, 'years').toDate()).max(moment().add(-21, 'year').toDate()),
    gender: yup.string().required(),
    citizenship: yup.string().required(),
}

const validatePassport = {
    holderDocSeries: yup.string().required().matches(/^[a-zA-Z]$/),
    holderDocNumber: yup.string().required().matches(/^[0-9№]{8,15}$/),
}

const fields1step = (isEci) => {
    const data = isEci ? [
        'carMark',
        'carModel',
        'carIssueYear',
        'policeSumInsured',
        'carBodyType',
        'vin',
        'bodyNumber',
        'gosNomer',
        'registrSvidet',
    ] : [
        'vin',
        'bodyNumber',
        'gosNomer',
        'registrSvidet',
        'carMark',
        'carModel',
        'carIssueYear',
    ]

    return data
}

const fields3step = [
    'duration',
    'holderFullName',
    'holderGender',
    'holderBirthday',
    'holderLastname',
    'holderFirstname',
    'holderPatronymic',
    'holderDocSeries',
    'holderDocType',
    'holderDocNumber',
    'holderIssueDate',
    'holderAddress',
    'holderPhone',
    'holderEmail',
]

const drivers3step = [
    'driverFullName',
    'driverLastname',
    'driverFirstname',
    'driverPatronymic',
    'docSeries',
    'docNumber',
    'docIssueDate',
    'driverGender',
    'driverCitizenship',
    'driverBd',
]

const fieldsNotStep = [
    'gosNomer',
    'holderLastname',
    'holderFirstname',
    'holderPatronymic',
    'carIssueYear',
    'holderPhone',
    'holderDocSeries',
    'holderDocType',
    'holderDocNumber',
]

export const products = [
    {
        code: 'SCI_P1',
        price: '500',
        class: 'auto-card__image-1',
        options: [1],
    },
    {
        code: 'SCI_P2',
        price: '1000',
        class: 'auto-card__image-2',
        options: [1, 2],
    },
    {
        code: 'SCI_P3',
        price: '1500',
        class: 'auto-card__image-3',
        options: [1, 2, 3],
    },
    {
        code: 'SCI_P4',
        price: false,
        class: 'auto-card__image-4',
    },
]

export const AutoContext = createContext()

export default function AutoContextWrapper({ children }) {
    const marksRequest = useGetRequest({ url: REST.CAR.MARKS })
    const { couponFrom } = useContext(Context)

    const { product: productCode } = useParams()
    const product = useMemo(() => (productCode === 'ECI' ? 'ECI_P1' : productCode), [])
    const eciCodes = useMemo(() => ['ECI', 'ECI_P1', 'ECI_P2', 'ECI_P3'], [])
    const isEci = useMemo(() => eciCodes.includes(product.toUpperCase()), [product])
    const steps = useMemo(() => (isEci ? [1, 2, 3, 4, 5] : ['not-step']), [isEci])
    const [cost, setCost] = useState(false)
    const [costMonth, setCostMonth] = useState(false)
    const [fullCost, setFullCost] = useState(false)
    const [step, setStep] = useState(isEci ? 1 : 'not-step')
    const [errorMsg, setErrorMsg] = useState([])
    const [marks, setMarks] = useState([])
    const [marksMap, setMarksMap] = useState([])
    const [models, setModels] = useState([])
    const [modelsMap, setModelsMap] = useState([])
    const [carIssueYear, setCarIssueYear] = useState([])
    const [autoSum, setAutoSum] = useState([])
    const [modelMarkMap, setModelMarkMap] = useState({ model: {}, marks: {} })
    const [errorCoupon, setErrorCoupon] = useState(false)
    const [shortValidate, setShortValidate] = useState(false)
    const [canCopy, setCanCopy] = useState(true)
    const [firstScreen, setFirstScreen] = useState(true)
    const timer = useRef()
    const installmentPlan = useMemo(() => [
        {
            code: 'installmentplan_1sum',
            date: moment(),
            percent: 50,
        },
        {
            code: 'installmentplan_2sum',
            date: moment().add('1', 'month'),
            percent: 25,
        },
        {
            code: 'installmentplan_3sum',
            date: moment().add('2', 'month'),
            percent: 25,
        },
    ], [])

    const calc = usePostRequest({ url: isEci ? REST.CAR_ECI.CALC : REST.CAR.CALC })
    const save = usePostRequest({ url: REST.CAR.SAVE })
    const payment = usePostRequest({ url: REST.PAYMENT })

    const validate = async (values) => {
        const errors = {}
        if (shortValidate) return {}

        switch (step) {
        case 'not-step':
            if (!values.carIssueYear) errors.carIssueYear = true

            fieldsNotStep.forEach((item) => {
                if (validateRules[item]) {
                    if (!validateRules[item].isValidSync(values[item])) {
                        if (['gosNomer'].indexOf(item) !== -1) {
                            // errors[item] = validateRules[item].validateSync(values[item])
                            errors[item] = true
                            try {
                                validateRules[item].validateSync(values[item])
                            } catch (e) {
                                if (['notTaxi'].indexOf(e.type) !== -1) {
                                    errors[item] = e.message
                                }
                            }
                        } else {
                            errors[item] = true
                        }
                    }
                }
            })

            if (!values.bodyNumber && !('vin' in errors)) {
                delete errors.bodyNumber
            }
            if (!values.vin && !('bodyNumber' in errors)) {
                delete errors.vin
            }
            break

        case 1:
            if (!values.carMark && !values.markaTxt) errors.carMark = true
            if (!values.carModel && !values.modelTxt) errors.carModel = true
            if (!values.carIssueYear) errors.carIssueYear = true

            fields1step(isEci).forEach((item) => {
                if (validateRules[item]) {
                    if (!validateRules[item].isValidSync(values[item])) {
                        if (['gosNomer'].indexOf(item) !== -1) {
                            // errors[item] = validateRules[item].validateSync(values[item])
                            errors[item] = true
                            try {
                                validateRules[item].validateSync(values[item])
                            } catch (e) {
                                if (['notTaxi'].indexOf(e.type) !== -1) {
                                    errors[item] = e.message
                                }
                            }
                        } else {
                            errors[item] = true
                        }
                    }
                }
            })

            if (!values.bodyNumber && !('vin' in errors)) {
                delete errors.bodyNumber
            }
            if (!values.vin && !('bodyNumber' in errors)) {
                delete errors.vin
            }
            break

        case 2:
            if (values.program.indexOf('_') === -1) errors.program = true
            break

        case 3:
        case 4:
            if (isEci && step === 3) break
            fields3step.forEach((item) => {
                if (validateRules[item]) {
                    if (!validateRules[item].isValidSync(values[item])) {
                        errors[item] = true
                    }
                }
            })

            if (values.holderDocType === '0' || values.holderDocType === 0) {
                (['holderDocSeries', 'holderDocNumber']).forEach((code) => {
                    if (validatePassport[code]) {
                        if (!validatePassport[code].isValidSync(values[code])) {
                            errors[code] = true
                        }
                    }
                })
            }

            if (values.multiDriveOption === 0) {
                values.driver.forEach((driver, i) => {
                    drivers3step.forEach((item) => {
                        if (validateRules[item]) {
                            if (!validateRules[item].isValidSync(driver[item])) {
                                errors[`driver[${i}]${item}`] = true
                            }
                        }
                    })
                })
            }

            if (!values.agree) {
                errors.agree = true
            }
            break
        default:
        }

        if (Object.keys(errors).length !== 0) {
            errors.haveErrors = true
            // eslint-disable-next-line prefer-destructuring
            errors.firstError = Object.keys(errors)[0]
        }

        return errors
    }

    const formik = useFormik({
        initialValues: initialValues(product, couponFrom),
        validate,
        onSubmit: ((values, { resetForm, validateForm }) => {
            window.clearTimeout(timer.current)
            setShortValidate(false)
            window.setTimeout(() => validateForm(), 0)

            setErrorMsg(false)
            setErrorCoupon(false)
            setCostMonth(false)
            setCost(false)
            setFullCost(false)
            setShortValidate(false)

            timer.current = window.setTimeout(async () => {
                const request = { ...values }

                if (request.carIssueYear) {
                    const {
                        success,
                        response,
                        error,
                    } = await calc.request({ data: createRequestDataV2(request, isEci) })

                    if (success && !error) {
                        resetForm({ values: { ...values, ...response } })
                        setShortValidate(false)
                        window.setTimeout(() => validateForm(), 0)
                        setCost(response.sumPrem)
                        setFullCost(parseInt(response.sumPremOrig, 10))
                        if (response.installmentplan1sum !== null) {
                            setCostMonth(
                                [
                                    response.installmentplan1sum,
                                    response.installmentplan2sum,
                                    response.installmentplan3sum,
                                    response.installmentplan4sum,
                                ],
                            )
                        }
                    } else {
                        setErrorCoupon(error.data.message)
                    }
                }
            }, 1000)
        }),
    })
    withFormikDevtools(formik)
    useEffect(() => {
        formik.setFieldValue('coupon', couponFrom)
    }, [couponFrom])

    useEffect(() => {
        formik.setFieldValue('program', product)
        formik.setFieldValue(product, 1)
    }, [])

    const debouceHandleSubmit = useMemo(() => debounce(() => formik.handleSubmit(), 200), [])

    const modelRequest = useGetRequest({ url: REST.CAR.MODELS.replace('{product_id}', formik.values.carMark) })
    // const yearRequest = useGetRequest({ url: REST.CAR.YEAR.replace('{product_id}', formik.values.carMark) })
    const sumRequest = useGetRequest({ url: REST.CAR.SUM.replace('{model_id}', formik.values.carMark) })

    const { accessToken } = useContext(AuthContext)
    useEffect(() => {
        if (accessToken) {
            marksRequest.request()
                .then(({ response, success }) => {
                    const values = []

                    if (success) {
                        const data = { ...modelMarkMap.marks }
                        const map = {}
                        response.forEach((item) => {
                            values.push({
                                value: item.syncId,
                                label: item.name,
                            })

                            map[item.syncId] = item.code
                            data[item.syncId] = item.name
                        })

                        setModelMarkMap({ ...modelMarkMap, marks: data })
                        setMarksMap(map)
                        setMarks(values)
                    }
                })
        }
    }, [accessToken])

    const { utmPartner } = useContext(Context)

    useEffect(() => {
        if (formik.values.paymentOper) showPayment()
    }, [formik.values.paymentOper])

    const [showPayment] = useModal(
        <PaymentSelector
            className="payment-popup" paymentOper={formik.values.paymentOper}
            setPaymentOper={(value) => {
                formik.setFieldValue('paymentOper', value)
            }}
            onCreateTravel={() => savePolice(formik.values)}
        />,
    )

    const savePolice = async (values) => {
        setErrorMsg(false)
        setErrorCoupon(false)

        let paymentOper = 'dc'
        switch (formik.values.paymentOper) {
        case 'vaslpay':
            paymentOper = 'vaslpay'
            break
        case 'payler':
            paymentOper = 'payler'
            break
        default:
            paymentOper = 'dc'
        }

        const request = { ...values }
        // request.mark = values.carMark
        request.mark = values.carMark ? marksMap[values.carMark] : values.markaTxt
        // request.model = values.carModel
        request.model = values.carModel ? modelsMap[values.carModel] : values.modelTxt
        request.sumPrem = cost
        request.partner = utmPartner
        const { success, response, error } = await save.request({ data: createRequestDataV2(request, isEci) })

        if (success && !error) {
            const data = { data: { policy_id: response.id, policy_type: 'car', payment_oper: paymentOper } }
            const answer = await payment.request(data)
            if (answer.success) window.location = answer.response.redirectUrl
        }
    }

    const getModels = async (val) => {
        const { success, response } = await modelRequest.request({
            url: REST.CAR.MODELS.replace('{product_id}', val),
        })

        if (success) {
            const values = []
            if (success) {
                const data = { ...modelMarkMap.model }
                const map = {}
                response.forEach((item) => {
                    values.push({
                        value: item.syncId,
                        label: item.name,
                    })

                    map[item.syncId] = item.code
                    data[item.syncId] = item.name
                })

                setModelMarkMap({ ...modelMarkMap, model: data })
                setModelsMap(map)
                setModels(values)
            }
        }

        setAutoSum([])
        formik.setFieldValue('policeSumInsured', 0)
    }

    const getSum = async (car, year = 2022) => {
        const { success, response } = await sumRequest.request({
            url: `${REST.CAR.SUM.replace('{model_id}', car)}?year=${year}`,
        })

        if (success) {
            setAutoSum(response.sumChoices.map((item) => ({ value: item, label: item })))
        }
    }

    const getYear = async (/* car */) => {
        // const { success, response } = await yearRequest.request({
        //     url: REST.CAR.YEAR.replace('{model_id}', car),
        // })
        //
        const data = []
        // if (success) {
        //     Object.values(response).forEach((item) => {
        //         data.push({
        //             value: item,
        //             label: item,
        //         })
        //     })
        // } else {
        const lastYear = moment().add(-21, 'year').year()
        for (let i = moment().year(); i > lastYear; i -= 1) {
            data.push({
                value: i,
                label: i,
            })
        }
        // }
        setCarIssueYear(data)
    }

    const nextStep = () => {
        const delta = step === 1 && !isEci ? 3 : 1
        setStep((value) => value + delta)
    }
    const prevStep = () => {
        setStep((value) => value - 1)
    }

    return (
        <AutoContext.Provider value={{
            eciCodes,
            product,
            steps,
            isEci,
            cost,
            setCost,
            costMonth,
            setCostMonth,
            step,
            setStep,
            errorMsg,
            setErrorMsg,
            marks,
            nextStep,
            prevStep,
            setMarks,
            models,
            setModels,
            getModels,
            getSum,
            autoSum,
            setAutoSum,
            modelMarkMap,
            errorCoupon,
            savePolice,
            modelsMap,
            marksMap,
            getYear,
            carIssueYear,
            setShortValidate,
            canCopy,
            setCanCopy,
            showPayment,
            fullCost,
            setFullCost,
            firstScreen,
            setFirstScreen,
            debouceHandleSubmit,
            installmentPlan,
        }}>
            <FormikProvider value={formik}>
                {children}
            </FormikProvider>
        </AutoContext.Provider>
    )
}
